import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { StaticQuery, Link } from 'gatsby';
import Image from 'gatsby-image';


import Facility_main_Container_block from '@components/facility/facility_main_container_block';

import Hero from '@components/en/common/hero';
import PageIntro from '@components/common/page-intro';
import ContentsIntro from '@components/common/contents-intro';
import ContactIntro from '@components/en/common/contact-intro';

import Hero__mds from '@img/svg/facility/hero__mds.svg';
import Service_guide__mds__strength from '@img/svg/common/service-guide__mds--strength.svg';
import Service_guide__mds__services from '@img/svg/common/service-guide__mds--services.svg';

import Layout from '@components/common/layout';
import { color, mixin, breakpoint } from '@src/settings';

const Facility_page = ({ data }) => (
	<PageCont baseBgColor={ color.paleGray }>
			<main>
				<Hero
					img={ data['heroImg'] }
					title={<Hero__mds />}
				/>
				
				<PageIntro
					title="The Machineries to support IKKANSEISAN System"
					subtitle={<>We are well equipped with extensive equipments and machines to support from development to production. We constantly adopt new technology, upgrade equipments and improve operational environment to ensure excellent SQCDM (Safety, Quality, Cost, Delivery, Morale).</>}
				 />
				
				<Facility_main_Container_block
					className="Facility_main_Container_block"

					title_01={<>Small Press</>}
						title_01_th_01={<>LINE</>}
						title_01_th_02={<>MACHINE TYPE</>}
						title_01_th_03={<>UNIT</>}
							title_01_td_01_01={<>A</>}
							title_01_td_01_02={<>300 T〜200 T</>}
							title_01_td_01_03={<>6</>}

							title_01_td_02_01={<>B</>}
							title_01_td_02_02={<>200 T</>}
							title_01_td_02_03={<>6</>}

							title_01_td_03_01={<>C</>}
							title_01_td_03_02={<>200 T~160 T</>}
							title_01_td_03_03={<>6</>}

							title_01_td_04_01={<>D</>}
							title_01_td_04_02={<>110 T</>}
							title_01_td_04_03={<>6</>}
								
							title_01_td_05_01={<>E</>}
							title_01_td_05_02={<>160 T</>}
							title_01_td_05_03={<>6</>}

							title_01_td_06_01={<>F</>}
							title_01_td_06_02={<>160 T</>}
							title_01_td_06_03={<>6</>}
						
					title_02={<>Medium &amp; Large</>}
						title_02_th_01={<>LINE</>}
						title_02_th_02={<>MACHINE TYPE</>}
						title_02_th_03={<>UNIT</>}
							title_02_td_01_01={<>I</>}
							title_02_td_01_02={<>500T - 400T - 400T - 400T</>}
							title_02_td_01_03={<>4</>}

							title_02_td_02_01={<>J</>}
							title_02_td_02_02={<>600T - 400T - 500T - 400T</>}
							title_02_td_02_03={<>4</>}

							title_02_td_03_01={<>K</>}
							title_02_td_03_02={<>1000T - 400T - 400T - 400T</>}
							title_02_td_03_03={<>4</>}

							title_02_td_04_01={<>-</>}
							title_02_td_04_02={<>2000T</>}
							title_02_td_04_03={<>1</>}

					title_03={<>Progressive</>}
						title_03_th_01={<>LINE</>}
						title_03_th_02={<>MACHINE TYPE</>}
						title_03_th_03={<>UNIT</>}
							title_03_td_01_01={<>P2</>}
							title_03_td_01_02={<>200T</>}
							title_03_td_01_03={<>1</>}

							title_03_td_02_01={<>P3</>}
							title_03_td_02_02={<>160T</>}
							title_03_td_02_03={<>1</>}

							title_03_td_03_01={<>P4</>}
							title_03_td_03_02={<>200T</>}
							title_03_td_03_03={<>1</>}
						
						title_04={<>Body Assembly</>}
						title_04_th_01={<>LINE</>}
						title_04_th_02={<>MACHINE TYPE</>}
						title_04_th_03={<>UNIT</>}
							title_04_td_01_01={<>PROJECTION SPOT</>}
							title_04_td_01_02={<>WELD LINEAR ACTION</>}
							title_04_td_01_03={<>14</>}

							title_04_td_02_01={<>CO2 WELD</>}
							title_04_td_02_02={<>ROBOT</>}
							title_04_td_02_03={<>10</>}

							title_04_td_03_01={<>ROBOTIC SPOT</>}
							title_04_td_03_02={<>WELD ROBOT</>}
							title_04_td_03_03={<>12</>}
						
					title_05={<>Precision Machining</>}
						title_05_th_01={<>LINE</>}
						title_05_th_02={<>MACHINE TYPE</>}
						title_05_th_03={<>UNIT</>}	
							title_05_td_01_01={<>MC1</>}
							title_05_td_01_02={<>CENTERING M/C</>}
							title_05_td_01_03={<>1</>}
	
							title_05_td_02_01={<>MC2</>}
							title_05_td_02_02={<>OKUMA HORIZON LATHE</>}
							title_05_td_02_03={<>1</>}

							title_05_td_03_01={<>MC3</>}
							title_05_td_03_02={<>OKUMA HORIZON LATHE</>}
							title_05_td_03_03={<>1</>}
	
							title_05_td_04_01={<>MC4</>}
							title_05_td_04_02={<>OKUMA BACK FACING</>}
							title_05_td_04_03={<>1</>}
	
							title_05_td_05_01={<>MC5</>}
							title_05_td_05_02={<>SHIGAYA ANGULAR SLIDE CYLINDER GRIND</>}
							title_05_td_05_03={<>1</>}	

							title_05_td_06_01={<>MC6</>}
							title_05_td_06_02={<>SHIGAYA ANGULAR SLIDE CYLINDER GRIND</>}
							title_05_td_06_03={<>1</>}
	
							title_05_td_07_01={<>MC7</>}
							title_05_td_07_02={<>OKUMA BORING</>}
							title_05_td_07_03={<>1</>}
	
							title_05_td_08_01={<>MC8</>}
							title_05_td_08_02={<>OKUMA GENOS TURNING</>}
							title_05_td_08_03={<>1</>}	

							title_05_td_09_01={<>MC9</>}
							title_05_td_09_02={<>EME MILLING</>}
							title_05_td_09_03={<>1</>}
	
							title_05_td_10_01={<>MC10</>}
							title_05_td_10_02={<>HANN KUEN CENTRE DRILL (RH)</>}
							title_05_td_10_03={<>1</>}	

							title_05_td_11_01={<>MC11</>}
							title_05_td_11_02={<>HANN KUEN CENTRE DRILL (LH)</>}
							title_05_td_11_03={<>1</>}

					title_06={<>Tooling Shop</>}
						title_06_th_01={<>LINE</>}
						title_06_th_02={<>MACHINE TYPE</>}
						title_06_th_03={<>UNIT</>}	
							title_06_td_01_01={<>CNC1</>}
							title_06_td_01_02={<>DAHLIN CNC MOULDING (2100 x 1700)</>}
							title_06_td_01_03={<>1</>}

							title_06_td_02_01={<>CNC2</>}
							title_06_td_02_02={<>DAHLIN CNC MOULDING (2300 x 1800)</>}
							title_06_td_02_03={<>1</>}

							title_06_td_03_01={<>CNC3</>}
							title_06_td_03_02={<>VAMAGE CNC MOULDING (1200 x 600)</>}
							title_06_td_03_03={<>1</>}

							title_06_td_04_01={<>CNC4</>}
							title_06_td_04_02={<>VISION WIDE TECH 3 AXIS (3000 x 2500)</>}
							title_06_td_04_03={<>1</>}

							title_06_td_05_01={<>CNC5</>}
							title_06_td_05_02={<>SNK 3 AXIS (4000 x 2500)</>}
							title_06_td_05_03={<>1</>}

							title_06_td_06_01={<>MILLING</>}
							title_06_td_06_02={<>TOOLING MILL M/C</>}
							title_06_td_06_03={<>5</>}

							title_06_td_07_01={<>GRINDING</>}
							title_06_td_07_02={<>TOOLING GRIND M/C</>}
							title_06_td_07_03={<>3</>}

							title_06_td_08_01={<>DRILLING</>}
							title_06_td_08_02={<>TOOLING RADIAL DRILL</>}
							title_06_td_08_03={<>3</>}

							title_06_td_09_01={<>LASER1</>}
							title_06_td_09_02={<>FUSECO LAYOUT M/C</>}
							title_06_td_09_03={<>1</>}

							title_06_td_10_01={<>LASER2</>}
							title_06_td_10_02={<>NYC LASER CUTTING</>}
							title_06_td_10_03={<>1</>}

							title_06_td_11_01={<>LASER3</>}
							title_06_td_11_02={<>NYC LASER CUTTING</>}
							title_06_td_11_03={<>1</>}

							title_06_td_12_01={<>LASER4</>}
							title_06_td_12_02={<>KOMATSU TLM 614 LASER CUTTING</>}
							title_06_td_12_03={<>1</>}

							title_06_td_13_01={<>TRYOUT PRESS</>}
							title_06_td_13_02={<>KOMATSU 1000 TON</>}
							title_06_td_13_03={<>1</>}

							title_06_td_14_01={<>DIE SPOT MACHINE</>}
							title_06_td_14_02={<>50 TON</>}
							title_06_td_14_03={<>1</>}


				/>

				<ContentsIntro
					firstItem="strength"
					secondItem="services"
				/>
				<ContactIntro />
			</main>
	</PageCont>
)

const PageCont = styled(Layout)`
.Facility_main_Container_block{
	margin: 10.5rem 0;
	${ mixin.baseWidthSet }
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-top: 5rem;
		margin-bottom: 6rem;
	`}
}

`;

export const query = graphql`
	query facilityImgs_en{

		heroImg: file( relativePath: { eq: "facility/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
	},
`

export default Facility_page

