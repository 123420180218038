import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { color, breakpoint } from "@src/settings";

const Facility_main_Container_block = (props) => (
	<Cont className={ props.className }>
    <div className="content">
      
      <h3>{ props.title_01 }</h3>
      <div className="content__item">
        <table>
          <thead>
            <tr className="content__item--row">
              <th>{ props.title_01_th_01 }</th>
              <th>{ props.title_01_th_02 }</th>
              <th>{ props.title_01_th_03 }</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ props.title_01_td_01_01 }</td>
              <td>{ props.title_01_td_01_02 }</td>
              <td>{ props.title_01_td_01_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_01_td_02_01 }</td>
              <td>{ props.title_01_td_02_02 }</td>
              <td>{ props.title_01_td_02_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_01_td_03_01 }</td>
              <td>{ props.title_01_td_03_02 }</td>
              <td>{ props.title_01_td_03_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_01_td_04_01 }</td>
              <td>{ props.title_01_td_04_02 }</td>
              <td>{ props.title_01_td_04_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_01_td_05_01 }</td>
              <td>{ props.title_01_td_05_02 }</td>
              <td>{ props.title_01_td_05_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_01_td_06_01 }</td>
              <td>{ props.title_01_td_06_02 }</td>
              <td>{ props.title_01_td_06_03 }</td>
            </tr>
          </tbody>
        </table>
      </div>  {/* .content__item*/}
              
      <h3>{ props.title_02 }</h3>
      <div className="content__item">
        <table>
          <thead>
            <tr className="content__item--row">
              <th>{ props.title_02_th_01 }</th>
              <th>{ props.title_02_th_02 }</th>
              <th>{ props.title_02_th_03 }</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ props.title_02_td_01_01 }</td>
              <td>{ props.title_02_td_01_02 }</td>
              <td>{ props.title_02_td_01_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_02_td_02_01 }</td>
              <td>{ props.title_02_td_02_02 }</td>
              <td>{ props.title_02_td_02_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_02_td_03_01 }</td>
              <td>{ props.title_02_td_03_02 }</td>
              <td>{ props.title_02_td_03_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_02_td_04_01 }</td>
              <td>{ props.title_02_td_04_02 }</td>
              <td>{ props.title_02_td_04_03 }</td>
            </tr>
          </tbody>
        </table>
      </div>  {/* .content__item*/}
      
      <h3>{ props.title_03 }</h3>
      <div className="content__item">
        <table>
          <thead>
            <tr className="content__item--row">
              <th>{ props.title_03_th_01 }</th>
              <th>{ props.title_03_th_02 }</th>
              <th>{ props.title_03_th_03 }</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ props.title_03_td_01_01 }</td>
              <td>{ props.title_03_td_01_02 }</td>
              <td>{ props.title_03_td_01_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_03_td_02_01 }</td>
              <td>{ props.title_03_td_02_02 }</td>
              <td>{ props.title_03_td_02_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_03_td_03_01 }</td>
              <td>{ props.title_03_td_03_02 }</td>
              <td>{ props.title_03_td_03_03 }</td>
            </tr>
          </tbody>
        </table>
      </div>  {/* .content__item*/}

      <h3>{ props.title_04 }</h3>
      <div className="content__item">
        <table>
          <thead>
            <tr className="content__item--row">
              <th>{ props.title_04_th_01 }</th>
              <th>{ props.title_04_th_02 }</th>
              <th>{ props.title_04_th_03 }</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ props.title_04_td_01_01 }</td>
              <td>{ props.title_04_td_01_02 }</td>
              <td>{ props.title_04_td_01_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_04_td_02_01 }</td>
              <td>{ props.title_04_td_02_02 }</td>
              <td>{ props.title_04_td_02_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_04_td_03_01 }</td>
              <td>{ props.title_04_td_03_02 }</td>
              <td>{ props.title_04_td_03_03 }</td>
            </tr>
          </tbody>
        </table>
      </div>  {/* .content__item*/}


      <h3>{ props.title_05 }</h3>
      <div className="content__item">
        <table>
          <thead>
            <tr className="content__item--row">
              <th>{ props.title_05_th_01 }</th>
              <th>{ props.title_05_th_02 }</th>
              <th>{ props.title_05_th_03 }</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ props.title_05_td_01_01 }</td>
              <td>{ props.title_05_td_01_02 }</td>
              <td>{ props.title_05_td_01_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_02_01 }</td>
              <td>{ props.title_05_td_02_02 }</td>
              <td>{ props.title_05_td_02_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_03_01 }</td>
              <td>{ props.title_05_td_03_02 }</td>
              <td>{ props.title_05_td_03_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_04_01 }</td>
              <td>{ props.title_05_td_04_02 }</td>
              <td>{ props.title_05_td_04_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_05_01 }</td>
              <td>{ props.title_05_td_05_02 }</td>
              <td>{ props.title_05_td_05_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_06_01 }</td>
              <td>{ props.title_05_td_06_02 }</td>
              <td>{ props.title_05_td_06_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_07_01 }</td>
              <td>{ props.title_05_td_07_02 }</td>
              <td>{ props.title_05_td_07_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_08_01 }</td>
              <td>{ props.title_05_td_08_02 }</td>
              <td>{ props.title_05_td_08_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_09_01 }</td>
              <td>{ props.title_05_td_09_02 }</td>
              <td>{ props.title_05_td_09_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_10_01 }</td>
              <td>{ props.title_05_td_10_02 }</td>
              <td>{ props.title_05_td_10_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_05_td_11_01 }</td>
              <td>{ props.title_05_td_11_02 }</td>
              <td>{ props.title_05_td_11_03 }</td>
            </tr>
          </tbody>
        </table>
      </div>  {/* .content__item*/}

      <h3>{ props.title_06 }</h3>
      <div className="content__item">
        <table>
          <thead>
            <tr className="content__item--row">
              <th>{ props.title_06_th_01 }</th>
              <th>{ props.title_06_th_02 }</th>
              <th>{ props.title_06_th_03 }</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ props.title_06_td_01_01 }</td>
              <td>{ props.title_06_td_01_02 }</td>
              <td>{ props.title_06_td_01_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_02_01 }</td>
              <td>{ props.title_06_td_02_02 }</td>
              <td>{ props.title_06_td_02_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_03_01 }</td>
              <td>{ props.title_06_td_03_02 }</td>
              <td>{ props.title_06_td_03_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_04_01 }</td>
              <td>{ props.title_06_td_04_02 }</td>
              <td>{ props.title_06_td_04_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_05_01 }</td>
              <td>{ props.title_06_td_05_02 }</td>
              <td>{ props.title_06_td_05_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_06_01 }</td>
              <td>{ props.title_06_td_06_02 }</td>
              <td>{ props.title_06_td_06_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_07_01 }</td>
              <td>{ props.title_06_td_07_02 }</td>
              <td>{ props.title_06_td_07_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_08_01 }</td>
              <td>{ props.title_06_td_08_02 }</td>
              <td>{ props.title_06_td_08_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_09_01 }</td>
              <td>{ props.title_06_td_09_02 }</td>
              <td>{ props.title_06_td_09_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_10_01 }</td>
              <td>{ props.title_06_td_10_02 }</td>
              <td>{ props.title_06_td_10_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_11_01 }</td>
              <td>{ props.title_06_td_11_02 }</td>
              <td>{ props.title_06_td_11_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_12_01 }</td>
              <td>{ props.title_06_td_12_02 }</td>
              <td>{ props.title_06_td_12_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_13_01 }</td>
              <td>{ props.title_06_td_13_02 }</td>
              <td>{ props.title_06_td_13_03 }</td>
            </tr>
            <tr>
              <td>{ props.title_06_td_14_01 }</td>
              <td>{ props.title_06_td_14_02 }</td>
              <td>{ props.title_06_td_14_03 }</td>
            </tr>
          </tbody>
        </table>
      </div>  {/* .content__item*/}
    </div>  {/* .content*/}
	</Cont>
);

export default Facility_main_Container_block;

const Cont = styled.div`
	width: 90%;
	background-color: #FFF;

	th, td {
		font-size: 1.8rem;
		padding-top: 2.3rem;
		padding-bottom: 2.3rem;
		${ media.lessThan(`${ breakpoint.sp }px`)`
			font-size: 1.3rem;
		`}
	}

	td {
		border-bottom: 0.1rem solid #DBDCDD;
		color: ${color.txtLight};
	}

	tr{
		th:nth-child(1), td:nth-child(1) {
			width: 27rem;
			text-align: left;
			padding-left: 1.5rem;
		}
		th:nth-child(2), td:nth-child(2)  {
			width: auto;
			text-align: left;
		}
		th:nth-child(3), td:nth-child(3) {
			width: 6.3rem;
			text-align: center;
		}
		${ media.lessThan(`${ breakpoint.sp }px`)`
		th:nth-child(1), td:nth-child(1) {
		width: auto;
		}
		`}
	}

.content{
  padding: 2rem 5.7rem 6.2rem;

    h3{
      margin-top: 5.5rem;
      background: ${color.blue};
      height: 6.2rem;
      padding: 0 2.3rem;
      font-size: 1.8rem;
      line-height: 6.2rem;
      color: white;
        img{
          width: 14.1rem;
        }
    }

    &__item{
      padding-top: 2.2rem;
        &--row th{
          border-bottom: 0.1rem solid ${color.blue};
          color: ${color.blue};
        }
    }

    &__item table{
      width: 100%;
    }
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		th, td {
			padding-top: 0;
		}
		.content{
			padding: 1rem 3rem;
			h3{
				margin-top: 2rem;
			}
		}
	`}
`;
